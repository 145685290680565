/* src/Dashboard.css */

  
  .time-filter-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 0px;
  }
  
  .filter-button {
    padding: 8px 16px;
    background-color: #e0e0e0;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .filter-button.active,
  .filter-button:hover {
    background-color: #007acc;
    color: white;
  }
  
  .dealer-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    
  }
  
  .dealer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.2s;
  }
  
  .dealer-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-metric {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
  
  .card-metric svg {
    margin-right: 8px;
  }
  
  .dealer-detail {
    background-color: #fff;
    width: 100%;          /* Let it stretch across the available space */
    margin: 0;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .back-button {
    margin-bottom: 20px;
    background: none;
    border: none;
    color: #007acc;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .graphs-section {
    margin-top: 20px;
  }
  
  .graph-placeholder {
    background-color: #f0f0f0;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px dashed #ccc;
  }
  

  /* Style the data table */
.data-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders collapse into a single border */
  margin: 20px 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Style table headers */
.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

/* Style table cells */
.data-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

/* Optional: add a zebra stripe for better readability */
.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Optional: add hover effects */
.data-table tr:hover {
  background-color: #e9e9e9;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.grid-container {
  display: grid;
  /* Use 2 columns on larger screens; adjust as needed */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  /* Remove or increase max-width to avoid the narrow center column */
  /* max-width: 1200px; */ /* <-- comment out or remove this if you want full-width */

  /* Instead of centering (margin: 0 auto), just add some horizontal padding or margin */
  margin: 0 0.5rem;  /* This adds spacing on left and right, but does not center everything */
  /* padding: 1rem;  <-- Alternatively, you could use padding */
  
  /* If you want items themselves to align left inside their cells: */
  align-items: stretch;
  justify-items: stretch; 
}

/* For very small screens, go single-column if you like */

@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .reviews-card {
    grid-column: auto; /* or grid-column: span 1; */
  }
}

@media screen and (min-width: 2500px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 3000px) {
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

/* Each grid item becomes a "card" */
.grid-item {
  display: flex;
  flex-direction: column;
  /* card-like styling */
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden; /* ensures the banner and the chart area share the same rounded corners */
  margin: 8px;      /* space between grid items; optional */
  background-color: #fff; 
}

/* Top banner (title area) */
.chart-banner {
  background-color: #666; 
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding: 12px; 
  /* No border-radius here; the .grid-item handles the corners */
}

.filter-button.active {
  background-color: #2196f3;
  color: #fff; /* Optional: change text color for better contrast */
}

.time-filter-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

/* Give the fieldset a border and place the legend "inside" it. */
.time-filter-fieldset {
  border: 1px solid #ccc;
  width: fit-content;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 6px;
  width: auto; 
  margin-bottom: 10px;
  position: relative; /* helps with legend positioning if you want to fine-tune */
}

/* Style the legend so it looks like a label sitting in the border */
.time-filter-fieldset legend {
  padding: 0 6px;       /* slight horizontal padding */
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 0.8rem;
  font-family: 'Montserrat', sans-serif;
  color: #666;
}


.dealercardbanner {
  margin: 1em;
}

.filter-button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Position the actual time-filter bar (buttons) as needed */
.time-filter-bar {
  display: flex;
  margin-left: 5px;
  width: auto;
  gap: 8px;
}

.time-filter-container h2 {
  margin: 0 0 10px;
  font-size: 1.1em;
}

/* The container for the actual chart */
.chart-container {
  /* fill remaining vertical space, if needed */
  flex: 1;
  /* Add internal padding so the chart has breathing room */
  padding: 12px;
  
  /* If you want the chart centered: */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  /* If you prefer a different background than the banner: */
  background-color: #f9f9f9;
}