/* Container for the entire metrics section */
.metrics-section.reviews {
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    font-family: sans-serif;
  }
  
  /* Header area with title and info icon */
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .section-header h4 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  /* Info icon styling */
  .info-icon {
    cursor: pointer;
    color: #888;
    font-size: 1rem;
  }
  
  /* Each review metric block (Google/Yelp) */
  .card-metric.review-metric {
    margin-top: 12px;
    padding: 12px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    background-color: #fafafa;
  }
  
  /* Review label with an icon */
  .review-label {
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  /* Container for star rating and review count */
  .review-details {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;
  }
  
  .review-count {
    font-weight: bold;
  }
  
  /* Change information styling */
  .review-change {
    font-size: 0.9rem;
    color: #555;
  }
  
  /* Positive and negative delta styling */
  .positive {
    color: green;
  }
  
  .negative {
    color: red;
  }
  