/* Import Poppins and Montserrat fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Montserrat:wght@600&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Poppins', sans-serif;
}

.App {
  /* Removed display: flex; */
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  z-index: 2;
}

.logo {
  width: 250px;
  margin-bottom: 1rem;
}

.user-info {
  margin-top: 1rem;
  text-align: center;
}

.logout-button {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  outline: none; /* Remove default outline */
  box-shadow: none; /* Remove default shadow */
  font-family: 'Poppins', sans-serif; /* Keep Poppins font */
}

.logout-button:hover {
  opacity: 0.8; /* Add a hover effect */
}

/* Sidebar Content */
.sidebar-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tabs Styling */
.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%; /* Make tabs container full width */
}

.tab {
  width: 80%; /* Set tab buttons to 80% width of sidebar */
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #444;
  color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
  font-weight: 600; /* Semi Bold */
  border-radius: 4px;
}

.tab.active {
  background-color: #2196f3;
}


/* Clear Cache Button Styling */
.clear-cache-button {
  background-color: #e74c3c !important; /* Red color to indicate caution */
}

.clear-cache-button:hover {
  background-color: #c0392b !important;
}

/* Main Content Container */
.main-content-container {
  margin-left: 300px; /* Same as sidebar width */
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh; /* Ensure it spans full viewport height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  display: flex;
  flex-direction: column;
}

/* Dealership Selector Styling */
.dealership-selector {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
}

.dealership-selector h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
}

.dealership-selector select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
}

/* Main Content Area */
.main-content {
  flex: 1;
  padding-top: 20px; /* Space below the dealership selector */
  text-align: left; /* Ensure text is left-aligned */
  display: flex;
  flex-direction: column;
}

/* Buttons Styling */
button {
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to all buttons */
  font-weight: 600; /* Semi Bold */
}

button:hover {
  opacity: 0.8; /* Add a hover effect */
}

button[disabled] {
  background-color: grey;
  cursor: not-allowed;
}

/* Map Styles */
.leaflet-container {
  height: 600px;
  width: 100%;
}

/* Charts Container Styling */
.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.chart-wrapper {
  flex: 1 1 45%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chart-wrapper h3 {
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
}

/* Ensure charts are responsive */
.responsive-chart {
  width: 100%;
  height: 300px;
}

/* Metric Cards Styling */
.metric-card {
  flex: 1 1 200px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
}

.live-metrics-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .chart-wrapper {
    flex: 1 1 100%;
  }

  .sidebar {
    width: 200px;
  }

  .main-content-container {
    margin-left: 200px; /* Adjust margin to match sidebar */
  }

  .dealership-selector h3 {
    font-size: 16px;
  }

  .dealership-selector select {
    font-size: 14px;
  }

  .tab {
    font-size: 14px;
    padding: 8px 12px;
  }

  .clear-cache-button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .metric-card {
    flex: 1 1 100%;
  }
}

.login-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Set the background color with opacity */
  background-color: rgba(51, 51, 51, 0.8); /* 0.8 means 80% opacity */

}


.login-container {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
}

.login-screen h1 {
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  color: #333; /* Adjust this color if needed */
}

.login-screen p {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #555; /* Adjust this color if needed */
}

.login-button {
  background-color: #2196f3; /* Adjust this color to match your brand */
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #1976d2; /* Adjust this color if needed */
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* App.css */

/* General Button Styles for Both Google and Microsoft */
.auth-button {
  background-color: #3b74e0; /* White background for both buttons */
  color: white; /* Black text */
  border: 1px solid #d9d9d9; /* Light gray border */
  display: flex;
  align-items: center;
  justify-content: center; /* Keep the text centered */
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  font-weight: normal; /* Ensure font is not bold */
  position: relative; /* Relative to contain absolute logo positioning */
  border-radius: 5px; /* Add rounded corners */
}

.auth-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.auth-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Google and Microsoft button logo positioning */
.google-logo, .microsoft-logo {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 13px; /* Align logos to the left */
}

/* Specific Styles for Google Button */
.google-button {
  background-color: #4285F4; /* Light background for Google button */
  color: #fff; /* Darker text color */
  border: 1px solid #ddd; /* Light border */
}

.google-button .button-text {
  flex: 1; /* Take up remaining space */
  text-align: center;
  font-weight: 500;
}

/* Specific Styles for Microsoft Button */
.microsoft-button {
  background-color: #2F2F2F; /* Dark grey background for Microsoft button */
  color: #fff;
}

.microsoft-button .button-text {
  flex: 1;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .auth-button {
    padding: 10px;
    font-size: 14px;
  }

  .google-logo, .microsoft-logo {
    width: 18px;
    height: 18px;
  }

  .button-text {
    font-size: 14px;
  }
}

/* Additional Styles for Overall Layout */

.login-container {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-logo {
  display: block;
  margin: 0 auto 20px auto;
  width: 220px; /* Adjust as needed */
}

.auth-form-container {
  margin-top: 20px;
}

.auth-form input {
  width: 100%;
  padding: 10px 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.message {
  text-align: center;
  margin-bottom: 15px;
  color: #ff4d4f;
}

.switch-auth {
  text-align: center;
  margin-top: 20px;
}

.switch-button {
  background: none;
  border: none;
  color: #4285f4;
  cursor: pointer;
  font-weight: 500;
}

.switch-button:hover {
  text-decoration: underline;
}

.google-logo {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 10px;
  background-color: #fff; /* White background for the logo */
  padding: 4px; /* Add padding inside the background */
  border-radius: 10%; /* Make the background circular */
}

.separator {
  transition: margin-top 0.3s ease;
}

.separator-signup {
  margin-top: 1rem;
  color: #555;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 600px) {
  .separator-signup {
    margin-top: 20px; /* Smaller margin on mobile devices */
  }
}

button, .metric-card, .chart-wrapper {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}


/* Container for Signup Success */
.signup-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

/* Login Button Style */
.login-button {
  background-color: #4285f4; /* Google Blue or any preferred color */
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #357ae8; /* Darker shade on hover */
}

.login-button:disabled {
  background-color: #a0c4f4;
  cursor: not-allowed;
}