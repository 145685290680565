/* Data.css */

  
  .time-filter-bar {
    margin-bottom: 5px;
  }
  
  .filter-button {
    margin-right: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .filter-button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .dealer-cards-grid {
    display: grid;
    gap: 10px;
    margin: 0; /* Aligns left by removing auto margins */
  }
  
  /* For screens under 1000px: single column */
  @media (max-width: 999px) {
    .dealer-cards-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* For screens between 1000px and 1500px: two columns */
  @media (min-width: 1000px) and (max-width: 1500px) {
    .dealer-cards-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* For screens between 1501px and 2499px: three columns */
  @media (min-width: 1501px) and (max-width: 2499px) {
    .dealer-cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* For screens 2500px and above: three columns with a max-width of 80% */
  @media (min-width: 2500px) {
    .dealer-cards-grid {
      grid-template-columns: repeat(3, 1fr);
      max-width: 80%;
    }
  }
  
  

  
  .dealer-detail {
    margin-top: 0px;
  }
  
  .back-button {
    margin-bottom: 10px;
    padding: 5px 10px;
  }
  
  .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .table-filters label {
    margin-right: 10px;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;
  }
  
  .data-table th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .link-buttons {
    display: flex;
    gap: 5px;
  }
  
  .detail-button,
  .copy-button,
  .export-button {
    padding: 5px 10px;
    cursor: pointer;
  }
  

  