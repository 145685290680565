/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
  }
  
  /* Make the entire component take up the full viewport height */
  .map-component-container {
    display: flex;
    width: 100%;
    height: 100vh; /* Full viewport height */
  }
  
  /* Sidebar filters on the left */
  .filters-container {
    width: 400px; /* Adjust as needed */
    padding: 10px;
    box-sizing: border-box;
    border-right: 1px solid #ddd;
    overflow-y: visible; /* Remove vertical scrolling */
  }
  
  /* Time filter button group */
  .time-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .time-filter-btn {
    flex: 1;
    margin-right: 5px;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 3px;
    transition: background-color 0.2s;
  }
  
  .time-filter-btn:last-child {
    margin-right: 0;
  }
  
  .time-filter-btn:hover {
    background-color: #e1e1e1;
  }
  
  .time-filter-btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  /* Map container on the right */
  .map-container {
    flex-grow: 1;
    position: relative;
  }
  
  /* Ensure the leaflet map fills its container */
  .leaflet-map {
    height: 100%;
    width: 100%;
  }
  
  /* Styling for custom select elements */
  .custom-select-container {
    margin-bottom: 15px;
  }
  
  .custom-select-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Loading Spinner */
  .map-loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Popup Content and Card Styles */
  .popup-content {
    font-size: 0.9rem;
    line-height: 1.4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .popup-content p {
    margin: 4px 0;
  }
  
  .popup-reviews {
    margin-top: 10px;
    font-size: 0.85rem;
    color: #555;
  }
  
  .popup-reviews div {
    margin-top: 4px;
  }
  
  /* Card container for dealership popup */
  .popup-card {
    padding: 8px;
    width: 260px;
    font-family: Arial, sans-serif;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Header styling including dealership name and carousel controls */
  .card-header {
    display: flex;
    flex-direction: column; /* Stack title and controls vertically */
    margin-bottom: 8px;
  }
  
  .card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2rem;
    /* Allow up to two lines before truncation */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .carousel-controls {
    align-self: flex-end;
    margin-top: 4px;
  }
  
  .carousel-controls button {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 0;
    margin: 0 4px;
  }
  
  /* Body of the popup for metrics and chart */
  .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .metrics {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .metric {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .metric .icon {
    margin-right: 4px;
  }
  
  /* Label styling for metric text (e.g., "Stock:" and "Avg Mileage:") */
  .metric .metric-label {
    margin-right: 2px;
  }
  
  /* Container for the chart */
  .chart-container {
    min-height: 120px;
    width: 100%;
    /* Remove flex centering */
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  