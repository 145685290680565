/* FeedbackComponent.css */

/* Apply box-sizing globally */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Montserrat:wght@600&display=swap');

.feedback-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feedback-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

.feedback-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group label span {
  color: #ff4d4d; /* Red color for required fields */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #2196f3;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  align-self: flex-end;
  outline: none;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #1976d2;
}

.thank-you-message {
  background-color: #e8f5e9;
  color: #388e3c;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
}
