


.dealer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.2s;
    display: flex;
    flex-direction: column;
  }
  
  .dealer-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Grid layout for sections */
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;
  }
  
  /* Section styling */
  .metrics-section {
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #eee;
  }
  
  .metrics-section h4 {
    margin: 0 0 8px 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 4px;
    font-size: 1.1em;
    font-weight: 600;
  }
  
  /* Metric styling */
  .card-metric {
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: 0.95em;
  }
  
  .card-metric svg {
    margin-right: 8px;
  }
  
  /* Positive/Negative value coloring */
  .positive {
    color: green;
    margin-left: 4px;
  }
  
  .negative {
    color: red;
    margin-left: 4px;
  }
  

  .mini-bar-chart {
    margin-top: 8px;
  }
  
  .mini-bar-chart .bar {
    height: 24px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    color: #fff;
  }
  
  .mini-bar-chart .top-bar {
    background-color: blue;  /* Color for top 3 models */
  }
  
  .mini-bar-chart .bottom-bar {
    background-color: red;   /* Color for bottom 3 models */
  }
  
  .review-metric {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }     
  
  .review-label,
  .review-details,
  .review-change {
    display: block;
    margin-bottom: 2px;
  }
  
  .review-details {
    display: flex;
    align-items: center;
  }
  
  .review-count {
    margin-left: 8px;
  }
  
  .card-metric.review-metric {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    
  }

  .star-rating svg.svg-inline--fa {
    margin-right: 0;
  }
  

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .info-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #888;
  }
  
  .info-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.3em; /* Increase the size */
    color: #aaa;    /* Lighter color */
  }
  
  .dealer-card.pending {
    position: relative;
  }
  
  .dealer-card.pending .actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .delete-button {
    font-size: 1.2rem; /* Slightly bigger */
    padding: 8px 12px;
  }


  @media screen and (min-width: 3000px) {
    .metrics-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  