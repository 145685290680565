/* MapComponent.module.css */

/* Component container */
.mapComponentContainer {
  display: flex;
  width: 100%;
  height: 100vh; /* Full viewport height */
}

/* Global styles: if you want these applied globally, wrap them in :global */
:global(body) {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

/* Sidebar filters on the left */
.filtersContainer {
  width: 400px; /* Adjust as needed */
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

/* Map container on the right */
.mapContainer {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

/* Ensure the leaflet map fills its container */
.leafletMap {
  height: 100%;
  width: 100%;
}

/* Styling for custom select elements */
.customSelectContainer {
  margin-bottom: 15px;
}

.customSelectLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Styling for the popup content */
.popupContent {
  font-size: 14px;
  line-height: 1.4;
  font-family: Montserrat, sans-serif;
}

.popupReviews div {
  margin-top: 4px;
}

/* Loading spinner styles */
.mapLoadingSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Popup styling */
.popupStat {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.popupStat i {
  color: #333;
}

.popupReviews {
  margin-top: 10px;
  font-size: 0.85rem;
  color: #555;
}

/* Popup Card (if used) */
.popupCard {
  padding: 10px;
  max-width: 260px;
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardHeader h3 {
  margin: 0;
  font-size: 1rem;
}

.carouselControls {
  display: flex;
  align-items: center;
}

.carouselControls button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin: 0 4px;
}

.cardBody {
  display: flex;
  flex-direction: column;
}

.metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.metric {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.metric .icon {
  margin-right: 4px;
  color: #555;
}

/* Graph container within the carousel */
.carouselGraph {
  min-height: 200px; /* Increased minimum height for the graph */
  margin-top: 8px;
}

/* Carousel container for popup stats */
.carouselContainer {
  font-family: Montserrat, sans-serif;
}

/* Carousel header styling */
.carouselHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

/* Carousel metric styling */
.carouselMetric {
  margin-bottom: 5px;
}

/* Star rating styling */
.starRating {
  margin-right: 4px;
}

/* In your Dashboard.css or MapComponent.module.css */
.carouselGraph {
  height: 50px; /* or any height that fits your design */
  width: 100%;
}

.reviewsContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem; /* adjust as needed */
}

.reviewColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  /* or center if you prefer star rating to be centered */
}

.popupStat {
  margin-bottom: 0.25rem;
}

.popupReviews {
  margin-bottom: 0.25rem;
}

.reviewsContainer {
  display: flex;
  gap: 1rem; /* space between Google/Yelp columns */
}

/* "Card" look for each review column */
.reviewCard {
  flex: 1;
  background-color: #f9f9f9; /* Light background */
  border-radius: 6px;
  padding: 0.5rem;
  text-align: left; /* Align text to the left */
}


.rangeSlider {
  margin-bottom: 5px;
  padding: 10px;
}

.sliderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.sliderLabel {
  font-size: 1em;
}

.sliderValues span {
  font-size: 0.9em;
  color: #555;
  margin-left: 10px;
}

.filterGroup h4 {
  margin-bottom: 5px;
}

.leaflet-popup-content {
  margin: 13px 24px 13px 20px !important;
}