@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body, .MuiTypography-root, h1 {
  font-family: 'Montserrat', sans-serif !important;
}

h1 {
  display: block; 
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.user-settings h1.MuiTypography-root {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 2em !important;
    font-weight: bold !important;
    margin-block-start: 0.67em !important;
    margin-block-end: 0.67em !important;
  }
  
  /* New save-button class to override width/padding */
.save-button {
    padding: 8px 16px; /* adjust as needed */
    width: auto; /* or a specific width if you prefer */
    min-width: initial;
  }